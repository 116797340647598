import hero from "./assets/logoKorsvagen.jpg";
import logo from "./assets/logo.jpg";
import { BsInstagram } from "react-icons/bs";

import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={hero} className="App-hero" alt="hero" />
        <h3>Sito in costruzione</h3>
        <div>
          <a
            className="flex-cont"
            href="https://www.instagram.com/korsvagensrl/">
            <img src="./assets/logo.jpg" className="App-logo" alt="logo" />

            <div className="flex-box">
              <p>Follow Us</p>
              <BsInstagram />
            </div>
          </a>
        </div>
      </header>
    </div>
  );
}

export default App;
